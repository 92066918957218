import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function useScrollTrigger() {
  const mm = gsap.matchMedia()

  return {
    st: ScrollTrigger,
    gsap: gsap,
    mm: mm,
  };
};