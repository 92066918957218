import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useStore} from "../stores/useStore";
import {getRoundData} from "../helpers/dataHelpers";

const Holder = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--windowHeight);
  pointer-events: none;
  z-index: 40;
  @media( ${props => props.theme.breakpoints.sm} ) {
    display: block;
  }
`;

const CircleLabel = styled.div`
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  color: var(--green);
  @media( ${props => props.theme.breakpoints.sm} ) {
    width: 10rem;
    height: auto;
  }

  p {
    text-align: center;
    margin: 0;
    ${(props) => props.theme.fluidType(-5)};
    @media( ${props => props.theme.breakpoints.sm} ) {
      padding: 1rem 0;
      ${(props) => props.theme.fluidType(0)};
    }
  }


  &.good {
    transform: translate(7.5rem, -20rem);
    @media( ${props => props.theme.breakpoints.sm} ) {
      p {
        transform: translateY(-100%);
      }
    }
  }

  &.bad {
    transform: translate(7.5rem, 15rem);
  }

  &.formVsStreak {
    &.good {
      transform: translate(8rem, -15rem);
    }

    &.bad {
      transform: translate(-12rem, 10rem);
    }
  }
  
  @media( ${props => props.theme.breakpoints.sm} ) {

    &.good {
      transform: translate(-15rem, -15rem);
    }

    &.bad {
      transform: translate(-15rem, 15rem);
    }

    &.formVsStreak {
      &.good {
        transform: translate(12.5rem, -15rem);
      }

      &.bad {
        transform: translate(-22.5rem, 15rem);
      }
    }
  }
`;

const Line = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5px;
  height: 30rem;
  background: var(--green);
  transform: translate(10rem, -50%);
  transition: transform 0.5s ease;
  opacity: 0.8;
  &.formVsStreak {
    transform: translate(-50%, -50%) rotate(45deg) scaleY(0.8);
  }
  @media( ${props => props.theme.breakpoints.sm} ) {
    transform: translate(-10rem, -50%);
    &.formVsStreak {
      transform: translate(-50%, -50%) rotate(45deg) scaleY(1.48);
    }
  }
`;

const ArrowHead = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid var(--green);
  transform: translateX(-4.5px);
  &:nth-child(2) {
    top: auto;
    bottom: 0;
    transform: translateX(-4.5px) rotate(180deg);
  }
`;

function Labels() {
  const focusTeam = useStore((state) => state.focusTeam);
  const view = useStore((state) => state.view);
  const ref = useRef(null);

  useEffect(
    () =>
      useStore.subscribe((state) => {
        const isGF = getRoundData(state.roundId).title === "Grand Final";
        if (isGF) {
          ref.current.style.opacity = 0;
        } else {
          ref.current.style.opacity = 1;
        }
      })
  );

  return (
    <Holder ref={ref}>
      {!focusTeam && (
        <>
          <CircleLabel className={`good ${view}`}>
            <p>“We're happy with the direction we're heading”</p>
          </CircleLabel>
          <CircleLabel className={`bad ${view}`}>
            <p>“We are sacking the coach”</p>
          </CircleLabel>
          <Line className={view}>
            <ArrowHead />
            <ArrowHead />
          </Line>
        </>
      )}
    </Holder>
  );
}

export default Labels;