import React from "react";
import styled from "styled-components";
import {teamData} from "../data/teamData";
import {useStore} from "../stores/useStore";
import { ReactComponent as EyeIcon } from "../assets/button-icons/eye-icon.svg"


const Holder = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 0.2rem;
    padding: 0.25rem;
    border-radius: 4px;
    line-height: 1.2;
    border: 0.5px solid white;
    color: white;
  }
`;

const Team = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: 0.2rem;

  button.team-name {

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    padding: 0.4rem;
    flex:1;


    div {
      height: 0.5rem;
      width: 0.5rem;
      margin: 0 0.3rem;
      border-radius: 50%;

    }
  }

`

const BottomButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0;

  button {
    margin-bottom: 0.2rem;
    padding: 0;
    font-size: 0.75rem;
    background-color: transparent;
    border: none;
  }

`

function TeamFilter() {
  const teams = useStore((state) => state.teams);
  const addTeam = useStore((state) => state.addTeam);
  const removeTeam = useStore((state) => state.removeTeam);
  const removeAllTeams = useStore((state) => state.removeAllTeams);
  const addAllTeams = useStore((state) => state.addAllTeams);

  const rgbToHex = (r, g, b) => {
    const hex = ((1 << 24) + (Math.round(r * 255) << 16) + (Math.round(g * 255) << 8) + Math.round(b * 255)).toString(16).slice(1);
    return "#" + hex;
  }

  const teamColors = []

  teamData.map((team) => {
    teamColors.push(rgbToHex(team.color.r, team.color.g, team.color.b));
    return teamColors;
  });

  return (
    <Holder>
      {teamData.map((team, index) => (
        <Team key={team.name}>
          <button className="team-name"
                  style={{
                    color: teams.includes(team.name) ? "var(--backgroundGreen)" : "inherit",
                    backgroundColor: teams.includes(team.name) ? "var(--green)" : "transparent",
                    border: teams.includes(team.name) ? "0.5px solid var(--green)" : "0.5px solid white",
                  }}
                  onClick={() => {
                    // When the button is clicked, add or remove the team from the teams array
                    if (teams.includes(team.name)) {
                      removeTeam(team.name);
                    } else {
                      addTeam(team.name);
                    }
                  }}
          >
            {team.name.toUpperCase()}
            <div style={{backgroundColor: teamColors[index]}}></div>
          </button>
          <button className="eyeicon"
                  onClick={() => {
                    removeAllTeams();
                    addTeam(team.name);
                  }}
          >
            <EyeIcon/>
          </button>
        </Team>
      ))}
      <BottomButtons>
        <button className="strip-btn-styles" onClick={() => removeAllTeams()}>DESELECT ALL</button>
        <button className="strip-btn-styles" onClick={() => addAllTeams()}>SELECT ALL</button>
      </BottomButtons>
    </Holder>
  );
}

export default TeamFilter;
