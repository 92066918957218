import React, {useState} from 'react'
import styled from 'styled-components'
import StartButton from "./StartButton";


const Holder = styled.div`
  cursor: pointer;
  position: fixed;
  height: var(--windowHeight);
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--backgroundGreen);
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    width: 117.92%;
    height: 25.92%;
    left: -7.85%;
    top: 93.82%;
    background-color: #004720;
    filter: blur(100px);
    border-radius: 50%;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

  p, h1 {
    color: white;
    margin: 0;
    text-align: center;
  }

  .title {
    ${(props) => props.theme.fluidType(10)};
    text-transform: uppercase;
    font-family: 'Chakra Petch', sans-serif;
    font-style: italic;
    font-weight: 700;
    line-height: 1;
  }

  h1 {
    color: var(--green);
  }
`


const OpeningScreen = () => {

  const [showOverlay, setShowOverlay] = useState(true);

  if(!showOverlay) return null;

  return (
    <Holder className={`overlay`}>
      <Inner>
        <p className="title">NRL</p>
        <h1 className="title">Interactive <br/>performance <br/>timeline</h1>
        <p>Powered by Supermarket</p>
        <StartButton clickHandler={() => setShowOverlay(false)}/>
      </Inner>
    </Holder>
  )
}

export default OpeningScreen