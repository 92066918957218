import React from 'react'
import styled from 'styled-components'
import arrowDown from "../assets/ui/arrow-down.png"
import useTrigger from "../hooks/useTrigger";

const Holder = styled.div`
  position: fixed;
  height: var(--windowHeight);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--backgroundGreen);
  transition: opacity 0.25s ease-out;
  pointer-events: none;
  color: black;

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }

`

const Outer = styled.div`
  background: rgba(1, 207, 93, 0.3);
  border-radius: 1rem;
  padding: 0.5rem;
`

const Middle = styled.div`
  background: rgba(1, 207, 93, 0.3);
  border-radius: 1rem;
  padding: 0.5rem;
`

const Inner = styled.div`
  height: 3rem;
  background: #01CF5D;
  border-radius: 1rem;
  font-size: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 1rem;
  padding: 0.5rem 1rem;

  img {
    height: 0.8rem;
  }
`

const ScrollToBegin = () => {

  const hide = useTrigger(0.001)

  return (
    <Holder style={{
      opacity: hide ? 0 : 1,
    }}>
      <Outer>
        <Middle>
          <Inner>
            <p>SCROLL TO BEGIN</p>
            <img src={arrowDown} alt="An arrow pointing downwards" />
          </Inner>
        </Middle>
      </Outer>
    </Holder>
  )
}

export default ScrollToBegin