import GlobalStyles from "./components/GlobalStyles";
import {Canvas} from "@react-three/fiber";
import {Environment} from "@react-three/drei";
import {theme} from "./helpers/styling";
import {ThemeProvider} from "styled-components";
import CanvasInner from "./components/CanvasInner";
import React, {useEffect} from "react";
import GsapWrapper from "./components/GsapWrapper";
// import { Perf } from "r3f-perf";
import {useStore} from "./stores/useStore";
import RoundTicker from "./components/RoundTicker";
import SupermarketOverlay from "./components/SupermarketOverlay";
import GrandFinalTakeover from "./components/GrandFinalTakeover";
import CameraRig from "./components/CameraRig";
import TeamInfo from "./components/TeamInfo";
import ToggleViewButtons from "./components/ToggleViewButtons";
import OpeningScreen from "./components/OpeningScreen";
import Background from "./components/Background";
import Labels from "./components/Labels";
import TeamFilterHolder from "./components/TeamFilterHolder";
import OpeningTeamFilter from "./components/OpeningTeamFilter";
import Scene from "./components/Scene";
import {useWindowSize} from "react-use";


function App() {
  const focusTeam = useStore((state) => state.focusTeam);
  const unFocusTeam = useStore((state) => state.unFocusTeam);
  const size = useWindowSize();

  useEffect(() => {
    document.documentElement.style.setProperty('--windowHeight', `${size.height}px`);
  }, [size]);

  useEffect(() => {
    const scrollHandler = () => {
      if (focusTeam) {
        unFocusTeam()
      }
    }
    window.addEventListener('scroll', scrollHandler);
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <OpeningScreen />
      <OpeningTeamFilter />
      <div>
        <Labels />
        <Background />
        <ToggleViewButtons />
        <RoundTicker />
        <SupermarketOverlay />
        <GsapWrapper>
          <Canvas
            shadows
            camera={{
              position: [0, 0, 10],
              fov: 30,
            }}
          >
            <Scene />
            {/*<Perf />*/}
            <ambientLight intensity={0.25} />
            <spotLight intensity={0.5} angle={0.2} penumbra={1} position={[30, 30, 30]} castShadow
                       shadow-mapSize={[512, 512]} />
            <directionalLight intensity={0.5} position={[-10, -10, -10]} color="white" />
            <CanvasInner />
            <GrandFinalTakeover />
            <CameraRig />
            <Environment files="warehouse.hdr" />
          </Canvas>
        </GsapWrapper>
        <TeamFilterHolder />
        <TeamInfo />
      </div>
    </ThemeProvider>
  );
}

export default App;
