import React from 'react';
import styled from 'styled-components';
import {useStore} from "../stores/useStore";
import {getTeamRoundData, getTeamData} from "../helpers/dataHelpers";
import LastFive from "./LastFive";

const Holder = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: black;
  z-index: 100;
  bottom: 0.5rem;
  left: 0.5rem;
  width: calc(100vw - 1rem);
  height: auto;
  @media( ${props => props.theme.breakpoints.sm} ) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 30rem;
  }
  @media( ${props => props.theme.breakpoints.md} ) {
    bottom: 4rem
  }
  @media( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr;
    top: 50%;
    bottom: auto;
    left: 10vw;
    width: 20vw;
    transform: translateY(-50%);
  }
  p {
    margin: 0;
  }

  .round-title {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 0.5px solid ;
    @media( ${props => props.theme.breakpoints.lg} ) {
      background: #404040;
      width: fit-content;
      padding: 0.5rem 0.75rem;
      border-radius: 3000px;
      font-weight: 500;
      margin: 0 0.5rem;
      border-bottom: none;
    }
  }
  
  h2, h3, p {
    ${(props) => props.theme.fluidType(-1)};
  }
  
  .colTwo {
    border-left: 0.5px solid #C8C8C8;
    @media( ${props => props.theme.breakpoints.lg} ) {
      border-left: none;
    }
  }

`;

const Info = styled.div`
  padding: 0.5rem;
`;

const LastFiveHolder = styled.div`
  padding: 0.5rem;
  border-top: 0.5px solid #C8C8C8;
  .last-five-title {
    margin-bottom: 0.3rem;
  }
`;

const Name = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 0.5px solid #C8C8C8;
  padding: 0.5rem;

  .color {
    height: 0.6rem;
    width: 0.6rem;
    margin-right: 0.3rem;
    border-radius: 50%;
  }

  h2 { margin: 0; }
`;

function TeamInfo() {
  const focusTeam = useStore((state) => state.focusTeam);
  const info = getTeamData(focusTeam)
  const roundId = useStore((state) => state.roundId);
  const roundData = getTeamRoundData(roundId, focusTeam);


  if (!focusTeam) return null;

  return (
    <Holder>
      <div>
      <Name>
        <div className="color" style={{backgroundColor: info.hex}}></div>
        <h2>{focusTeam}</h2>
      </Name>
      <Info>
        <p><em>Est</em> &bull; {info.established}</p>
        <p><em>Premierships</em> &bull; {info.premierships}</p>
        <p><em>Local</em> &bull; {info.local}</p>
        <p><em>GOAT</em> &bull; {info.goat}</p>
        <p><em>Future Immortal</em> &bull; {info.futureImmortal}</p>
      </Info>
      </div>
      <div className="colTwo">
      <h3 className="round-title">At {roundData.roundTitle}, {roundData.season}</h3>
      <Info>
        <p><em>Opposition</em> &bull; {roundData.opposition}</p>
        <p><em>Result</em> &bull; {roundData.result}</p>
        <p><em>Score</em> &bull; {roundData.score}</p>
        <p><em>Streak</em> &bull; {roundData.streak}</p>
      </Info>
      <LastFiveHolder>
        <p className="last-five-title">Last Five</p>
        <LastFive lastFive={roundData.lastFive} />
      </LastFiveHolder>
      </div>
    </Holder>
  )
}

export default TeamInfo;