import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from "prop-types";

const Holder = styled.div`
  margin-top: 2rem;
  position: relative;
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

const LoadingBar = styled.div`
  width: 10rem;
  height: 0.25rem;
  border-radius: 500px;
  background-color: #004720;
  position: absolute;
  top: calc(50% - 0.125rem);
  left: 0;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--green);
    top: 0;
    left: 0;
    animation: ${slideIn} 5s linear 1;
  }
`;

function StartButton({clickHandler}) {
  const [ showButton, setShowButton ] = useState( null );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Holder>
      {!showButton && <LoadingBar/>}
      <button style={{
        visibility: showButton ? 'visible' : 'hidden',
      }}
              className="green start" onClick={clickHandler}>Pick teams
      </button>
    </Holder>
  )
}

StartButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

export default StartButton;