import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useStore} from "../stores/useStore";
import {getRoundData, getTeamData} from "../helpers/dataHelpers";
import {gfWinners} from "../data/gfWinners";

const Holder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${props => getTeamData(props.team)?.hex};
  opacity: ${props => props.team ? 1 : 0};
  transition: opacity 0.25s ease-in-out;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%);
  }
  p {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100vw;
    max-width: 80rem;
    padding: 2rem;
    ${(props) => props.theme.fluidType(18)};
    text-align: center;
    color: #fff;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 700;
    font-style: italic;
    line-height: 0.8;
    br {
      display: none;
    }
    @media( ${props => props.theme.breakpoints.sm} ) {
      padding: 10rem;
      top: 50%;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      br {
        display: block;
      }
    }
  }
`;

function Background() {
  const focusTeam = useStore((state) => state.focusTeam);
  const ref = useRef(null);
  const team = useRef(null);
  const season = useRef(null);
  const text = useRef(null);

  useEffect(
    () =>
      useStore.subscribe((state) => {
        const isGF = getRoundData(state.roundId).title === "Grand Final";
        const winningTeam = gfWinners[getRoundData(state.roundId).season];
        if (isGF) {
          ref.current.style.background = getTeamData(winningTeam)?.hex;
          ref.current.style.opacity = 1;
          team.current.innerHTML = winningTeam;
          season.current.innerHTML = getRoundData(state.roundId).season;
          text.current.style.opacity = 0.5;
        } else {
          ref.current.style.background = getTeamData(state.focusTeam)?.hex;
          ref.current.style.opacity = state.focusTeam ? 1 : 0;
          text.current.style.opacity = 0;
        }
      })
  );

  return (
    <Holder ref={ref} team={focusTeam}>
      <p ref={text}><span ref={team}></span> <br/><br/><span ref={season}></span> Premiers</p>
    </Holder>
  )
}

export default Background;