import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const Holder = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.6rem !important;
  line-height: 1.2rem;

  span {
    text-align: center;
    padding: 0.2rem 0.2rem;
    width: 1.6rem;
    border-radius: 0.2rem;

    &.W {
      background: #005303;
      color: #44FF4B;
    }

    &.L {
      background: #930000;
      color: #EDFF7D;
    }

    &.D {
      background: #5E4900;
      color: #FFD100;
    }

    &.DP {
      background: #5B0063;
      color: #F586FF;
    }
  }
`;

function LastFive({lastFive}) {

  const convertResult = (result) => {
    if (result === "Win") {
      return "W";
    } else if (result === "Loss") {
      return "L";
    } else if (result === "Tie") {
      return "D";
    } else if (result === "No Match") {
      return "DP";
    }
  }

  return (
    <Holder>{lastFive.map((result, i) =>
      <span key={i} className={convertResult(result)}>{convertResult(result)}</span>
    )}</Holder>
  )
}

LastFive.propTypes = {
  lastFive: PropTypes.array.isRequired,
};

export default LastFive;