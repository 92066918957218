import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import useScrollTrigger from "../hooks/useScrollTrigger";
import { useStore } from "../stores/useStore";
import PropTypes from "prop-types";
import data from "../data/input_data.json";
import RoundAxis from "./RoundAxis";
import CrossHairAxis from "./CrossHairAxis";
import ScrollToBegin from "./ScrollToBegin";
import YearPicker from "./YearPicker";

const lengthOfScrollInVh = 4000;

const Holder = styled.div``;

const CanvasHolder = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--windowHeight);
`;

const LengthOfScroll = styled.div`
  position: relative;
  z-index: 100;
  height: calc(${lengthOfScrollInVh} / 100 * var(--windowHeight));
  width: 10px;
  //background-size: 40px 40px;
  //background-image: linear-gradient(to right, grey 1px, transparent 1px),
  //  linear-gradient(to bottom, grey 1px, transparent 1px);
`;

function GsapWrapper({ children, debug }) {
  const { st, gsap } = useScrollTrigger();
  const comp = useRef();
  const setRoundId = useStore((state) => state.setRoundId);
  const setScrollProgress = useStore((state) => state.setScrollProgress);
  const totalRounds = data['Brisbane Broncos'].length
  const view = useStore((state) => state.view);

  // console.log(data['Brisbane Broncos'][0]);

  useLayoutEffect(() => {
    // Using GSAP context: https://greensock.com/blog/learning/react/#context
    let ctx = gsap.context(() => {
      st.normalizeScroll(true);
      st.config({
        ignoreMobileResize: true,
      });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".lengthOfScroll",
            start: "top top",
            end: "bottom bottom",
            scrub: 0.5,
            onUpdate: (self) => {
              setScrollProgress(self.progress.toFixed(8));
              setRoundId(
                data["Brisbane Broncos"][
                  Math.min(
                    Math.floor(totalRounds * self.progress),
                    totalRounds - 1
                  )
                ]["round_id"]
              );
              // console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
            },
          },
        })
        .to(".round-axis", {
          xPercent: -100,
          duration: 1,
          ease: "none",
        }); // Overall duration is 1 to match the scroll progress
    }, comp);

    return () => ctx.revert(); // cleanup
  });

  return (
    <Holder ref={comp}>
      <YearPicker lengthOfScroll={lengthOfScrollInVh} />
      <ScrollToBegin />
      {view === "formVsStreak" && <CrossHairAxis />}
      <CanvasHolder>{children}</CanvasHolder>
      <LengthOfScroll className="lengthOfScroll" />
      <RoundAxis lengthOfScroll={lengthOfScrollInVh} />
    </Holder>
  );
}

GsapWrapper.propTypes = {
  debug: PropTypes.bool,
};

GsapWrapper.defaultProps = {
  debug: false,
};

export default GsapWrapper;
