import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 2.5rem;
  right: 0.5rem;
  color: var(--green);
  font-size: 9px;
`;

const SupermarketOverlay = () => {
  return (
    <Overlay>
      Built by <a href="https://www.supermarket.london">Supermarket</a>
    </Overlay>
  );
};

export default SupermarketOverlay;