import { useEffect, useRef, useState } from "react";
import {useStore} from "../stores/useStore";

export default function useTrigger(triggerStart, triggerEnd = null) {
  const [active, setActive] = useState(false);

  // Rapidly changing state: https://github.com/pmndrs/zustand#transient-updates-for-often-occurring-state-changes
  const progressRef = useRef(useStore.getState().scrollProgress);
  useEffect(() =>
    useStore.subscribe((state) => {
      progressRef.current = state.scrollProgress;
      // Set state to active if progress is after trigger start and, if trigger end exists, before trigger end
      triggerStart && setActive(
        progressRef.current >= triggerStart &&
          (triggerEnd === null || progressRef.current <= triggerEnd)
      );
    })
  );

  if(!triggerStart) return null;
  return active;
}

