import { create } from "zustand";
import { teamData } from "../data/teamData";
import data from "../data/input_data.json";

// Add all teams to the initial state for teams
const initialTeams = teamData.map((team) => team.name);
export const useStore = create((set) => ({
  scrollProgress: 0,
  setScrollProgress: (value) => set({ scrollProgress: value }),

  roundId: data["Brisbane Broncos"][0]["round_id"],
  setRoundId: (value) => set({ roundId: value }),

  view: "form",
  setView: (value) => set({ view: value }),

  teams: [],
  removeAllTeams: () => set({ teams: [] }),
  removeTeam: (value) => {
    set((state) => {
      const index = state.teams.indexOf(value);
      let newTeams = [...state.teams];
      if (index > -1) {
        newTeams.splice(index, 1);
      }
      return {
        teams: newTeams,
      };
    });
  },
  addTeam: (value) => {
    set((state) => {
      let newTeams = [...state.teams];
      if (!state.teams.includes(value)) {
        newTeams.push(value);
      }
      return {
        teams: newTeams,
      };
    });
  },
  addAllTeams: () => set({ teams: initialTeams }),

  focusTeam: false,
  focusTeamPosition: null,
  unFocusTeam: () =>
    set({
      focusTeam: false,
      focusTeamPosition: null,
    }),
  setFocusTeam: (name, position) =>
    set({
      focusTeam: name,
      focusTeamPosition: position,
    }),
}));
