import React from 'react';
import styled from 'styled-components';
import {useStore} from "../stores/useStore";
import {getRoundData} from "../helpers/dataHelpers";

const Holder = styled.div`
  position: fixed;
  z-index: 100;
  top: 0.5rem;
  left: 50%;
  @media ( ${props => props.theme.breakpoints.md} ) {
    left: auto;
    right: 50%;
    text-align: right;
  }

  p {
    margin: 0;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`;

function RoundTicker() {
  const roundId = useStore((state) => state.roundId);
  return (
    <Holder>
      <p>{getRoundData(roundId).season}</p>
      <p>{getRoundData(roundId).title}</p>
    </Holder>
  )
}

export default RoundTicker;