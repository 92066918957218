import React, {useEffect, useRef} from 'react'
import styled from "styled-components"
import {useStore} from "../stores/useStore";
import {getRoundData} from "../helpers/dataHelpers";

const Holder = styled.div`
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;

  div.x-axis {
    width: 60vw;
    border: 0.3px solid rgba(255,255,255,0.25);
    height: 1px;
    top: 0;
    left: 0;
    z-index: 20;
    position: absolute;
    transform: translateX(-50%);
    @media( ${props => props.theme.breakpoints.sm} ) {
      width: 70vh;
    }
    p {
      position: absolute;
      bottom: 0;
      right: 0;
      @media( ${props => props.theme.breakpoints.sm} ) {
        left: 0;
        right: auto;
      }
    }
  }

  div.y-axis {
    width: 1px;
    border: 0.3px solid rgba(255,255,255,0.25);
    height: 70vh;
    top: 0;
    left: 0;
    z-index: 20;
    position: absolute;
    transform: translateY(-50%);
    p {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-90deg);
      transform-origin: bottom left;
    }
  }
  
  p {
    font-size: 0.8rem;
    margin: 0;
  }

`;


const CrossHairAxis = () => {
const ref = useRef(null);
const focusTeam = useStore((state) => state.focusTeam);

  useEffect(
    () =>
      useStore.subscribe((state) => {
        const isGF = getRoundData(state.roundId).title === "Grand Final";
        if (isGF) {
          ref.current.style.opacity = 0;
        } else {
          ref.current.style.opacity = 1;
        }
      })
  );

  return (
    <Holder ref={ref}>
      {!focusTeam && (
        <>
          <div className="x-axis">
            <p>Streak</p>
          </div>
          <div className="y-axis">
            <p>Form</p>
          </div>
        </>
      )}
    </Holder>
  );
}

export default CrossHairAxis