import React from 'react';
import styled from 'styled-components';
import data from "../data/input_data.json";
import PropTypes from "prop-types";
import useScrollTrigger from "../hooks/useScrollTrigger";
import {useWindowSize} from "react-use";

const Holder = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  display: flex;
  z-index: 100;
  opacity: 1;
  transition: opacity 0.5s ease;

  &.hide {
    opacity: 0;
  }
`;

const Marker = styled.button`
  width: 5.3rem;
  position: relative;
  height: 2rem;
  

  p {
    position: absolute;
    top: 0;
    left: 0;
    ${(props) => props.theme.fluidType(-2)};
    margin: 0;
  }
`;

function RoundAxis({lengthOfScroll}) {
  const rounds = data['Brisbane Broncos']
  const {gsap} = useScrollTrigger();
  const {height} = useWindowSize();

  const clickHandler = (index) => {
    const totalScrollLength = lengthOfScroll * (height / 100) - height;
    const scrollLength = totalScrollLength / rounds.length;

    gsap.to(window, {
      duration: 0.5,
      scrollTo: index * scrollLength + 1,
    });
  }

  return (
    <Holder className={`round-axis`}>
      {rounds.map((round, index) =>
        <Marker key={round['round_id']} className="strip-btn-styles" onClick={()=>clickHandler(index)}>
            {/*<p className="season">{round['round_title'] === 'Round 1' ? round['season'] : ''}</p>*/}
            <p>{round['round_title'].replace('Round ', '').replace('Finals Week', 'FW').replace('Grand Final', 'GF')}</p>
        </Marker>
      )}
    </Holder>
  )
}

RoundAxis.propTypes = {
  lengthOfScroll: PropTypes.number.isRequired,
};

export default RoundAxis;