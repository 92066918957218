import gsap from "gsap";
import React from "react";
import { useWindowSize } from "react-use";
import styled from "styled-components";
import data from "../data/input_data.json";
import {useStore} from "../stores/useStore";
import {getRoundData} from "../helpers/dataHelpers";

const YearHolder = styled.div`
  position: fixed;
  top: 50%;
  left: 0.6rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.3rem;
  transform: translateY(-50%);
  button {
    margin-bottom: 1rem;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    span {
      margin-right: 1rem;
      background-color: #fff;
      display: inline-block;
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &.current {
      opacity: 1;
      span {
        opacity: 1;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;



const YearPicker = ({ lengthOfScroll }) => {
  const { height } = useWindowSize();
  const rounds = data["Brisbane Broncos"];
  const roundId = useStore((state) => state.roundId);
  const currentSeason = getRoundData(roundId).season

  // Get a list of the indexes of the grand final of each season
  let indexList= [];

  for(let i = 0; i < rounds.length; i++) {
    if(i === rounds.length-1 || rounds[i+1].season !== rounds[i].season) {
      indexList.push({index: i, year: rounds[i].season});
    }
  }

  const clickHandler = (i) => {
    const totalScrollLength = lengthOfScroll * (height / 100) - height;
    const scrollLength = totalScrollLength / rounds.length;

    gsap.to(window, {
      duration: 2,
      scrollTo: scrollLength * indexList[i].index + 1,
    });
  };
  return (
    <YearHolder>
      {indexList.map((year, i) => (
        <button key={i} className={`strip-btn-styles${currentSeason === year.year ? ' current' : ''}`} onClick={() => clickHandler(i)}>
          <span/>{year.year}
        </button>
      ))}
    </YearHolder>
  );
};

export default YearPicker;