const randomVectorRed = (r) => [r / 2 - Math.random() * r, r / 2 - Math.random() * r, r / 2 - Math.random() * r]
const randomEulerRed = () => [Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI]
const colors = ["#FF55C4", "#A3F535", "#F34343", "#239AE6", "#FED91A"]
const randomColor = () => colors[Math.floor(Math.random() * colors.length)]
const confettiData = Array.from({ length: 100 }, (r = 7) => ({
  random: Math.random(),
  position: randomVectorRed(r),
  rotation: randomEulerRed(),
  color: randomColor()
}))

export { confettiData }
