import React from 'react';
import PropTypes from "prop-types";
import {Html} from "@react-three/drei";
import {getTeamData, getTeamRoundData} from "../helpers/dataHelpers";
import {useStore} from "../stores/useStore";

function TeamInfoAtRound({show, team}) {
  const roundId = useStore((state) => state.roundId);
  const roundData = getTeamRoundData(roundId, team);
  const teamData = getTeamData(team);


  return (
    <group visible={show}>
        {show && <Html
          style={{
            backgroundColor: '#000000',
            borderRadius: '5px',
            width: '200px',
            fontSize: '12px',
            textAlign: 'left',
            transform: `translate(20%, -50%)`,
          }}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderBottom: "0.5px solid black" }}>
          <div style={{ height: "0.5rem", width: "0.5rem", margin: "0 5px", borderRadius: "50%", background: teamData.hex}}></div>
          <p style={{  margin: 0, padding: "5px 2px" }}>{team}</p>
          </div>
          <div style={{ padding: "5px" }}>

          <p style={{  margin: 0, }}>{roundData.roundTitle}</p>
          <p style={{  margin: 0, }}>Opposition: {roundData.opposition}</p>
          <p style={{  margin: 0, }}>Result: {roundData.result}</p>
          <p style={{  margin: 0, }}>Score: {roundData.score}</p>
          <p style={{  margin: 0, }}>Streak: {roundData.streak}</p>
          </div>
          <div style={{ borderTop: "0.5px solid black" }}>
            <p style={{  margin: 0, paddingLeft: "5px" }}>Last Five</p>
          <p style={{ margin: 0, display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "4px", padding: "5px", lineHeight: "20px"}}>
            {roundData.lastFive.map((result, i) => {
            return (
              <span key={i} style={result === "Win" ? { textAlign: "center", padding: "4px 4px", width: "24px", borderRadius: "2px", background: "#005303", color: "#44FF4B"} : result === "No Match" ? { textAlign: "center", padding: "4px", width: "24px", borderRadius: "2px", background: "#5B0063", color: "#F586FF"} : result === "Loss" ? { textAlign: "center", padding: "4px", width: "24px", borderRadius: "2px", background: "#930000", color: "#EDFF7D"} : result === "Tie" ? { textAlign: "center", padding: "4px", width: "24px", borderRadius: "2px", background: "5E4900", color: "#FFD100"} : { textAlign: "center", padding: "4px", width: "24px", borderRadius: "2px", background: "none"} }>{result.replace("Win", "W").replace("No Match", "DP").replace("Loss", "L").replace("Tie", "D")}</span>
            )
          })}</p>
          </div>
        </Html>}
    </group>
  )
}

TeamInfoAtRound.propTypes = {
  team: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

TeamInfoAtRound.defaultProps = {
  show: true,
};

export default TeamInfoAtRound;