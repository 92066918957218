import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {Box} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";
import * as THREE from 'three';
import {useStore} from "../stores/useStore";
import TeamInfoAtRound from "./TeamInfoAtRound";
import {getTeamData} from "../helpers/dataHelpers";
import TeamSphere from "./TeamSphere";

function TeamLine({data, name, xUnit}) {
  const teams = useStore((state) => state.teams);
  const focusTeam = useStore((state) => state.focusTeam);
  const setFocusTeam = useStore((state) => state.setFocusTeam);
  const unFocusTeam = useStore((state) => state.unFocusTeam);

  let debug = false;
  const animatedSphere = useRef();
  const colourCurveRef = useRef();
  const greyCurveRef = useRef();
  const color = getTeamData(name)['color'];
  const view = useStore((state) => state.view);
  const [hovered, setHover] = useState(false)

  // Set up the initial points for the curve
  let initialPoints = [];
  for (let i = 0; i < data.length; i++) {
    initialPoints.push(new THREE.Vector3(i * xUnit, data[i]['form_after_round'], data[i]['streak_after_round'] * 0.125 * -1))
  }

  // Create a CatMulRom curve that will be used as the geometry for the drawn line
  const curve = new THREE.CatmullRomCurve3(initialPoints);
  const points = curve.getPoints(data.length * 10);

  // Create a colour geometry from the points
  const colorGeometry = new THREE.BufferGeometry().setFromPoints(points);
  // Set initial draw range to 0
  colorGeometry.setDrawRange(0, 0);

  // Create a colour geometry from the points
  const greyGeometry = new THREE.BufferGeometry().setFromPoints(points);

  useEffect(() => {
    if (colourCurveRef.current) {
      // Hide the line if the team is not in the teams array or there is a focus team that is not this team

      if (!teams.includes(name) || (focusTeam && focusTeam !== name)) {
        colourCurveRef.current.visible = false;
        greyCurveRef.current.visible = false;
        animatedSphere.current.visible = false;
      } else {
        colourCurveRef.current.visible = true;
        greyCurveRef.current.visible = true;
        animatedSphere.current.visible = true;
      }
    }
  }, [teams, name, focusTeam]);

  useFrame((state, damp) => {
    const progress = useStore.getState().scrollProgress
    const view = useStore.getState().view

    // If we are scrolling then...
    if (
      (
        animatedSphere.current.position.x !== curve.getPoint(progress).x ||
        animatedSphere.current.position.y !== curve.getPoint(progress).y ||
        animatedSphere.current.position.z !== curve.getPoint(progress).z
      ) &&
      animatedSphere.current
    ) {

      //Move the sphere...
      animatedSphere.current.position.x = curve.getPoint(progress).x;
      animatedSphere.current.position.y = curve.getPoint(progress).y;
      animatedSphere.current.position.z = curve.getPoint(progress).z;

      // And draw the line...
      colourCurveRef.current.geometry.setDrawRange(0, (data.length * 10 * progress).toFixed(0));
    }

    // Set rotation of the Team Sphere
    if(focusTeam === name) {
      let toPosition = [0,0,0];
      if(view === 'form') {
        toPosition = [0, -Math.PI / 2, 0]
      } else if(view === 'streak') {
        toPosition = [-Math.PI / 2, -Math.PI / 2, 0]
      } else if(view === 'formVsStreak') {
        toPosition = [0, 0, 0]
      }
      animatedSphere.current.rotation.y = THREE.MathUtils.damp(animatedSphere.current.rotation.y, toPosition[1], 0.2, damp * 20);
      animatedSphere.current.rotation.x = THREE.MathUtils.damp(animatedSphere.current.rotation.x, toPosition[0], 0.2, damp * 20);

    } else {
      let toPosition = [0,0,0];
      if(view === 'form') {
        toPosition = [0, Math.PI / 2, 0]
      } else if(view === 'streak') {
        toPosition = [-Math.PI / 2, Math.PI / 2, 0]
      } else if(view === 'formVsStreak') {
        toPosition = [0, Math.PI, 0]
      }
      animatedSphere.current.rotation.y = THREE.MathUtils.damp(animatedSphere.current.rotation.y, toPosition[1], 0.2, damp * 20);
      animatedSphere.current.rotation.x = THREE.MathUtils.damp(animatedSphere.current.rotation.x, toPosition[0], 0.2, damp * 20);
    }

  })

 
  const clickHandler = () => {
    if(focusTeam) {
      unFocusTeam()
    } else if (teams.includes(name)) {
      setFocusTeam(name, animatedSphere.current.position)
    }
  }

  

  return (
    <>
      <group visible={view !== 'formVsStreak'}>
        <line ref={greyCurveRef} geometry={greyGeometry}>
          <lineBasicMaterial attach="material" color={'#004626'} />
        </line>
      </group>
      <line ref={colourCurveRef} geometry={colorGeometry}>
        <lineBasicMaterial attach="material" color={color} />
      </line>
      {debug && initialPoints.map((point, index) =>
        <Box position={point} key={index} args={[0.1, 0.1, 0.1]}>
          <meshStandardMaterial color={'red'} />
        </Box>
      )}
      <group ref={animatedSphere} position={initialPoints[0]}>
        <TeamSphere teamData={getTeamData(name)} clickHandler={() => clickHandler()} setHover={(val) => setHover(val)} color={color} focused={focusTeam === name}/>
        <TeamInfoAtRound team={name} show={!focusTeam && teams.includes(name) && hovered}/>
      </group>
    </>
  )
}

TeamLine.propTypes = {
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  xUnit: PropTypes.number.isRequired,
};

export default TeamLine;