import React from 'react'
import styled from 'styled-components';
import {useStore} from "../stores/useStore";
import { ReactComponent as FormIcon } from "../assets/button-icons/form-icon.svg"
import { ReactComponent as StreakIcon } from "../assets/button-icons/streak-icon.svg"
import { ReactComponent as FormStreakIcon } from "../assets/button-icons/form-streak-icon.svg"

const ButtonHolder = styled.div`
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.3rem;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 0.8rem;
    width: 100%;
    padding: 0.3rem 1rem;
    border-radius: 8px;
    margin-bottom: 0.3rem;
    border: 1px solid white;
    background: transparent;

    svg {
      height: 2rem;
      display:none; 
    }

    &.active {
      color: var(--backgroundGreen);
      background: var(--green);
      border:1px solid var(--green);
    
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: var(--lighterGreen);
    }
    &.active:hover {
      background: var(--green);
    }
  }
`;

const ToggleViewButtons = () => {

  const view = useStore((state) => state.view);
  const setView = useStore((state) => state.setView);

  return (
    <ButtonHolder>
      <button className={view === "form" ? `toggle active` : `toggle`} onClick={() => setView("form")}>FORM<FormIcon/></button>
      <button className={view === "streak" ? `toggle active` : `toggle`} onClick={() => setView("streak")}>STREAK<StreakIcon/></button>
      <button className={view === "formVsStreak" ? `toggle active` : `toggle`} onClick={() => setView("formVsStreak")}>FORM VS STREAK<FormStreakIcon/></button>
    </ButtonHolder>
  )
}

export default ToggleViewButtons