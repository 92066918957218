import React from 'react';
import PropTypes from "prop-types";
import {Decal, Shadow, Sphere, useTexture} from "@react-three/drei";
import {MathUtils} from "three";
import {useStore} from "../stores/useStore";


const scales = {
  type: 0.039,
  icon: 0.06,
  player: 0.018,
  bar: 0.028,
  combined: 0.022,
}

function TeamSphere({clickHandler, setHover, teamData, focused, noClick, noHover, showExtraStickers}) {

  const teams = useStore((state) => state.teams);

  const textureProps = useTexture({
    map: teamData.strip,
  })

  const [type, icon, player, bar, combined] = useTexture([
    teamData.type,
    teamData.icon,
    teamData.player,
    teamData.bar,
    teamData.combined,
  ])

  return (
    <group>
      <Sphere
        args={[0.1, 64, 64]}
        onClick={() => !noClick && clickHandler?.()}
        onPointerOver={(event) => {
          if (!noHover && !focused) {
            if(teams.includes(teamData.name)){
              event.stopPropagation();
            }
            if (typeof setHover === "function") {
              setHover(true)
            }
          }
        }}
        onPointerOut={() => {
          if (!noHover && !focused) {
            if (typeof setHover === "function") {
              setHover(false)
            }
          }
        }}
        castShadow receiveShadow
      >
        <meshPhysicalMaterial
          {...textureProps}
          roughness={0}
          envMapIntensity={0.2}
          color={"#ffffff"}
          toneMapped={false} />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[0.079, 0, 0]} // Position of the decal
          rotation={[0, MathUtils.degToRad(90), MathUtils.degToRad(18)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[4 * scales.type, scales.type, 0.1]} // Scale of the decal
          map={type} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-10}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[0.079, 0.055, -0.02]} // Position of the decal
          rotation={[MathUtils.degToRad(-113), MathUtils.degToRad(57), MathUtils.degToRad(98)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[4 * scales.combined, 2 * scales.combined, 0.1]} // Scale of the decal
          map={combined} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-5}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[0.079, -0.045, 0.025]} // Position of the decal
          rotation={[MathUtils.degToRad(53), MathUtils.degToRad(57), MathUtils.degToRad(-27)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[scales.icon, scales.icon, 0.1]} // Scale of the decal
          map={icon} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-15}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[0.079, 0.035, 0.04]} // Position of the decal
          rotation={[MathUtils.degToRad(-51), MathUtils.degToRad(51), MathUtils.degToRad(73)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[scales.player * 4, scales.player * 3, 0.1]} // Scale of the decal
          map={player} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-15}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[0.079, -0.035, -0.045]} // Position of the decal*/}
          rotation={[MathUtils.degToRad(160), MathUtils.degToRad(57), MathUtils.degToRad(-118)]} // Rotation of the decal (can be a vector or a degree in radians)*/}
          scale={[scales.bar * 4, scales.bar, 0.1]} // Scale of the decal*/}
          map={bar} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-20}
        />





        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[-0.079, 0, 0]} // Position of the decal
          rotation={[0, MathUtils.degToRad(-90), MathUtils.degToRad(18)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[4 * scales.type, scales.type, 0.1]} // Scale of the decal
          map={type} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-10}
          visible={showExtraStickers}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[-0.079, 0.055, 0.02]} // Position of the decal
          rotation={[MathUtils.degToRad(-70), MathUtils.degToRad(-61), MathUtils.degToRad(-96)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[4 * scales.combined, 2 * scales.combined, 0.1]} // Scale of the decal
          map={combined} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-5}
          visible={showExtraStickers}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[-0.079, -0.045, -0.025]} // Position of the decal
          rotation={[MathUtils.degToRad(118), MathUtils.degToRad(-55), MathUtils.degToRad(138)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[scales.icon, scales.icon, 0.1]} // Scale of the decal
          map={icon} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-15}
          visible={showExtraStickers}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[-0.079, 0.035, -0.04]} // Position of the decal
          rotation={[MathUtils.degToRad(-145), MathUtils.degToRad(-58), MathUtils.degToRad(-108)]} // Rotation of the decal (can be a vector or a degree in radians)
          scale={[scales.player * 4, scales.player * 3, 0.1]} // Scale of the decal
          map={player} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-15}
          visible={showExtraStickers}
        />

        <Decal
          // debug // Makes "bounding box" of the decal visible
          position={[-0.079, -0.035, 0.045]} // Position of the decal*/}
          rotation={[MathUtils.degToRad(27), MathUtils.degToRad(-60), MathUtils.degToRad(70)]} // Rotation of the decal (can be a vector or a degree in radians)*/}
          scale={[scales.bar * 4, scales.bar, 0.1]} // Scale of the decal*/}
          map={bar} map-anisotropy={16}
          envMapIntensity={0.2}
          roughness={0}
          polygonOffsetFactor={-20}
          visible={showExtraStickers}
        />

      </Sphere>
      <Shadow scale={0.3} position={[0, -0.15, 0]} rotation={[Math.PI / 2, 0, 0]} opacity={0.15} visible={focused} />
    </group>
  )
}

TeamSphere.propTypes = {
  clickHandler: PropTypes.func,
  setHover: PropTypes.func,
  teamData: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  noClick: PropTypes.bool,
  noHover: PropTypes.bool,
  showExtraStickers: PropTypes.bool,
};

TeamSphere.defaultProps = {
  noClick: false,
  noHover: false,
  showExtraStickers: false,
};

export default TeamSphere;