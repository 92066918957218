import data from "../data/input_data.json";
import {teamData} from "../data/teamData";

export const getRoundData = (roundId) => {
  const isRound = (dataEntry) => dataEntry['round_id'] === roundId;
  const index = data['Brisbane Broncos'].findIndex(isRound)

  if(index < 0) {
    return null
  } else {
    return {
      title: data['Brisbane Broncos'][index]['round_title'],
      season: data['Brisbane Broncos'][index]['season']
    }
  }
}

export const getTeamRoundData = (roundId, teamName) => {

  if(!roundId || !teamName) return null;

  const isRound = (dataEntry) => dataEntry['round_id'] === roundId;
  const index = data[teamName].findIndex(isRound)
  const teamData = data[teamName][index]

  let lastFive = []
  for (let i = 0; i < 5; i++) {
    if (index - i >= 0) {
      lastFive.push(data[teamName][index - i]['result'])
    }
  }

  if(index < 0) {
    return null
  } else {
    return {
      name: teamData.name,
      roundTitle: teamData['round_title'],
      season: teamData['season'],
      formIn: index > 0 ? data[teamName][index-1]['form_after_round'] : null,
      formOut: teamData['form_after_round'],
      opposition: teamData['opposition'],
      result: teamData['result'],
      score: teamData['score'],
      streak: teamData['streak_after_round'],
      premiers: teamData['won_premiership'],
      lastFive: lastFive.length > 0 ? lastFive : null
    }
  }
}

export const getTeamData = (teamName) => {
  const isTeam = (team) => team.name === teamName;
  const index = teamData.findIndex(isTeam)

  if(index < 0) {
    return null
  } else {
    return teamData[index]
  }
}