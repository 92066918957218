import React, {useState} from 'react';
import styled from 'styled-components';
import TeamFilter from "./TeamFilter";
import {ReactComponent as Icon} from "../assets/ui/filter-icon.svg"

const Holder = styled.div`
  position: fixed;
  z-index: 100;
  top: 1rem;
  right: 1rem;

  button {
    position: relative;
    z-index: 3;
    svg {
      width: 1.5rem;
      stroke: white;
    }
  }
`;

const FilterHolder = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundGreen);
  padding: 5rem 1rem 1rem 1rem;
  z-index: 2;
  @media( ${props => props.theme.breakpoints.sm} ) {
    position: absolute;
    top: calc(100% + 1rem);
    right: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    padding: 0;
  }
`;

function TeamFilterHolder() {
  const [open, setOpen] = useState(false);

  return (
    <Holder>
      <button className="strip-btn-styles" onClick={() => setOpen(!open)}><Icon /></button>
      {open && <FilterHolder>
        <TeamFilter />
      </FilterHolder>}
    </Holder>
  )
}

export default TeamFilterHolder;