import React, {useRef} from 'react';
import * as THREE from 'three'
import {useFrame} from "@react-three/fiber";
import {Instance, Instances} from '@react-three/drei';
import {confettiData} from '../data/confettiData';
import {useStore} from "../stores/useStore";
import {getRoundData, getTeamData} from "../helpers/dataHelpers";
import TeamSphere from "./TeamSphere";
import {gfWinners} from "../data/gfWinners";

const geometry = new THREE.CylinderGeometry(5, 5, 1, 32);
const material = new THREE.MeshStandardMaterial({
  roughness: 0,
});

function GrandFinalTakeover() {

  const confettiGroupRef = useRef()
  const sphereHolderRef = useRef()
  const panthersSphere = useRef()
  const roostersSphere = useRef()
  const cowboysSphere = useRef()
  const stormSphere = useRef()
  const sharksSphere = useRef()

  useFrame((state, delta) => {
    confettiGroupRef.current.rotation.y = state.clock.getElapsedTime() / 10;
    sphereHolderRef.current.rotation.y = -state.clock.getElapsedTime();
    const roundId = useStore.getState().roundId;
    const isGF = getRoundData(roundId).title === "Grand Final";
    const winningTeam = gfWinners[getRoundData(roundId).season];

    if (isGF) {
      sphereHolderRef.current.scale.x = THREE.MathUtils.damp(sphereHolderRef.current.scale.x, 8, 0.1, delta * 40)
      sphereHolderRef.current.scale.y = THREE.MathUtils.damp(sphereHolderRef.current.scale.y, 8, 0.1, delta * 40)
      sphereHolderRef.current.scale.z = THREE.MathUtils.damp(sphereHolderRef.current.scale.z, 8, 0.1, delta * 40)
    } else {
      sphereHolderRef.current.scale.x = THREE.MathUtils.damp(sphereHolderRef.current.scale.x, 0, 0.1, delta * 80)
      sphereHolderRef.current.scale.y = THREE.MathUtils.damp(sphereHolderRef.current.scale.y, 0, 0.1, delta * 80)
      sphereHolderRef.current.scale.z = THREE.MathUtils.damp(sphereHolderRef.current.scale.z, 0, 0.1, delta * 80)
    }

    panthersSphere.current.visible = false;
    roostersSphere.current.visible = false;
    cowboysSphere.current.visible = false;
    stormSphere.current.visible = false;
    sharksSphere.current.visible = false;

    if (isGF) {
      if (winningTeam === 'Penrith Panthers') {
        panthersSphere.current.visible = true;
      } else if (winningTeam === 'Sydney Roosters') {
        roostersSphere.current.visible = true;
      } else if (winningTeam === 'North QLD Cowboys') {
        cowboysSphere.current.visible = true;
      } else if (winningTeam === 'Melbourne Storm') {
        stormSphere.current.visible = true;
      } else if (winningTeam === 'Cronulla Sharks') {
        sharksSphere.current.visible = true;
      }
    }
  })

  return (
    <group>
      <group ref={confettiGroupRef}>
        <Instances range={100} material={material} geometry={geometry}>
          <group position={[0, 0, 0]}>
            {confettiData.map((props, i) => (
              <Disc key={i} {...props} />
            ))}
          </group>
        </Instances>
      </group>
      <group ref={sphereHolderRef} scale={8}>
        <group ref={panthersSphere}><TeamSphere teamData={getTeamData('Penrith Panthers')} noClick showExtraStickers
                                                focused /></group>
        <group ref={roostersSphere}><TeamSphere teamData={getTeamData('Sydney Roosters')} noClick showExtraStickers
                                                focused /></group>
        <group ref={cowboysSphere}><TeamSphere teamData={getTeamData('North QLD Cowboys')} noClick showExtraStickers
                                               focused /></group>
        <group ref={stormSphere}><TeamSphere teamData={getTeamData('Melbourne Storm')} noClick showExtraStickers
                                             focused /></group>
        <group ref={sharksSphere}><TeamSphere teamData={getTeamData('Cronulla Sharks')} noClick showExtraStickers
                                              focused /></group>
      </group>
    </group>
  )
}


function Disc({random, grandFinal, ...props}) {
  const ref = useRef()

  useFrame((state, delta) => {
    const t = state.clock.getElapsedTime() + random * 300
    ref.current.rotation.set(Math.cos(t / 4) / 2, Math.sin(t / 4) / 2, Math.cos(t / 1.5) / 2)
    ref.current.position.y = Math.sin(t / 1.5) / 2

    const roundId = useStore.getState().roundId;
    const isGF = getRoundData(roundId).title === "Grand Final";

    if (isGF) {
      ref.current.scale.x = THREE.MathUtils.damp(ref.current.scale.x, 1, 0.1, delta * 20)
      ref.current.scale.y = THREE.MathUtils.damp(ref.current.scale.y, 1, 0.1, delta * 20)
      ref.current.scale.z = THREE.MathUtils.damp(ref.current.scale.z, 1, 0.1, delta * 20)
    } else {
      ref.current.scale.x = THREE.MathUtils.damp(ref.current.scale.x, 0, 0.1, delta * 20)
      ref.current.scale.y = THREE.MathUtils.damp(ref.current.scale.y, 0, 0.1, delta * 20)
      ref.current.scale.z = THREE.MathUtils.damp(ref.current.scale.z, 0, 0.1, delta * 20)
    }
  })
  return (
    <group {...props} scale={0.015}>
      <Instance ref={ref} color={props.color} />
    </group>
  )
}

export default GrandFinalTakeover;