import React from 'react';
import data from "../data/input_data.json";
import TeamLine from "./TeamLine";
import {useFrame} from "@react-three/fiber";
import * as THREE from "three";
import {useStore} from "../stores/useStore";
import {getRoundData} from "../helpers/dataHelpers";

const xUnit = 0.5;
function CanvasInner() {
  const group = React.useRef();
  const innerGroup = React.useRef();
  const view = useStore((state) => state.view);

  useFrame((state, damp) => {
    const progress = useStore.getState().scrollProgress
    if(group.current) {
      if(view === 'form') {
        group.current.rotation.x = THREE.MathUtils.damp(group.current.rotation.x, 0, 0.1, damp * 40)
        group.current.rotation.y = THREE.MathUtils.damp(group.current.rotation.y, 0, 0.1, damp * 40)
      } else if(view === 'streak') {
        group.current.rotation.x = THREE.MathUtils.damp(group.current.rotation.x, Math.PI/2, 0.1, damp * 40)
        group.current.rotation.y = THREE.MathUtils.damp(group.current.rotation.y, 0, 0.1, damp * 40)
      } else {
        group.current.rotation.x = THREE.MathUtils.damp(group.current.rotation.x, 0, 0.1, damp * 40)
        group.current.rotation.y = THREE.MathUtils.damp(group.current.rotation.y, -Math.PI/2, 0.1, damp * 40)
      }
    }
    if(innerGroup.current ) {
      innerGroup.current.position.x = THREE.MathUtils.lerp(0, (data['Brisbane Broncos'].length - 1) * xUnit * -1, progress)
    }

    // If it is a GF then hide the inner group
    const roundId = useStore.getState().roundId;
    const isGF = getRoundData(roundId).title === "Grand Final";
    innerGroup.current.visible = !isGF;
  })

  return (
    <group ref={group}>
    <group ref={innerGroup}>
      {Object.keys(data).map((keyName, index) =>
        <TeamLine
          key={index}
          xUnit={xUnit}
          name={keyName}
          data={data[keyName]}/>
      )}
    </group>
    </group>
  )
}

export default CanvasInner;