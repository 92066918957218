import {useFrame} from "@react-three/fiber";
import {useStore} from "../stores/useStore";
import * as THREE from "three";

function CameraRig() {
  const focusTeam = useStore((state) => state.focusTeam);
  const focusTeamPosition = useStore((state) => state.focusTeamPosition);
  const view = useStore((state) => state.view);

  useFrame((state, delta) => {
    if (focusTeam) {
      if (focusTeamPosition) {
        if (view === 'form') {
          state.camera.position.x = THREE.MathUtils.damp(state.camera.position.x, 0, 0.1, delta * 40)
          state.camera.position.y = THREE.MathUtils.damp(state.camera.position.y, focusTeamPosition.y, 0.1, delta * 40)
          state.camera.position.z = THREE.MathUtils.damp(state.camera.position.z, focusTeamPosition.z + 1, 0.1, delta * 40)
          state.camera.lookAt(
            0,
            THREE.MathUtils.damp(state.camera.position.y, focusTeamPosition.y, 0.1, delta * 40),
            -10
          )
        } else if (view === 'streak') {
          state.camera.position.x = THREE.MathUtils.damp(state.camera.position.x, 0, 0.1, delta * 40)
          state.camera.position.y = THREE.MathUtils.damp(state.camera.position.y, focusTeamPosition.z * -1, 0.1, delta * 40)
          state.camera.position.z = THREE.MathUtils.damp(state.camera.position.z, focusTeamPosition.y + 1, 0.1, delta * 40)
          state.camera.lookAt(
            0,
            THREE.MathUtils.damp(state.camera.position.y, focusTeamPosition.z * -1, 0.1, delta * 40),
            -10,
          )
        } else if(view === 'formVsStreak') {
          state.camera.position.x = THREE.MathUtils.damp(state.camera.position.x, focusTeamPosition.z * -1, 0.1, delta * 40)
          state.camera.position.y = THREE.MathUtils.damp(state.camera.position.y, focusTeamPosition.y, 0.1, delta * 40)
          state.camera.position.z = THREE.MathUtils.damp(state.camera.position.z, 1, 0.1, delta * 40)
          state.camera.lookAt(
            THREE.MathUtils.damp(state.camera.position.x, focusTeamPosition.z * -1, 0.1, delta * 40),
            THREE.MathUtils.damp(state.camera.position.y, focusTeamPosition.y, 0.1, delta * 40),
            -10
          )
        }
      }
    } else {
      state.camera.position.x = THREE.MathUtils.damp(state.camera.position.x, 0, 0.1, delta * 40)
      state.camera.position.y = THREE.MathUtils.damp(state.camera.position.y, 0, 0.1, delta * 40)
      state.camera.position.z = THREE.MathUtils.damp(state.camera.position.z, 10, 0.1, delta * 40)
      state.camera.lookAt(
        THREE.MathUtils.damp(state.camera.position.x, 0, 0.1, delta * 40),
        THREE.MathUtils.damp(state.camera.position.y, 0, 0.1, delta * 40),
        -10
      )
    }
  })

  return null;
}

export default CameraRig;