import * as THREE from 'three';


//Broncos 

import broncosType from '../assets/broncos/brisbane-type.png'
import broncosIcon from '../assets/broncos/brisbane-icon.png'
import broncosPlayer from '../assets/broncos/brisbane-player.png'
import broncosBar from '../assets/broncos/brisbane-bar.png'
import broncosCombined from '../assets/broncos/brisbane-combo.png'

//Bulldogs

import bulldogsType from '../assets/bulldogs/bulldogs-type.png'
import bulldogsIcon from '../assets/bulldogs/bulldogs-icon.png'
import bulldogsPlayer from '../assets/bulldogs/bulldogs-player.png'
import bulldogsBar from '../assets/bulldogs/bulldogs-bar.png'
import bulldogsCombined from '../assets/bulldogs/bulldogs-combo.png'

//Cowboys

import cowboysType from '../assets/cowboys/cowboys-type.png'
import cowboysIcon from '../assets/cowboys/cowboys-icon.png'
import cowboysPlayer from '../assets/cowboys/cowboys-player.png'
import cowboysBar from '../assets/cowboys/cowboys-bar.png'
import cowboysCombined from '../assets/cowboys/cowboys-combo.png'

//Dragons

import dragonsType from '../assets/dragons/saints-type.png'
import dragonsIcon from '../assets/dragons/saints-icon.png'
import dragonsPlayer from '../assets/dragons/saints-player.png'
import dragonsBar from '../assets/dragons/saints-bar.png'
import dragonsCombined from '../assets/dragons/saints-combo.png'

//Eels

import eelsType from '../assets/eels/eels-type.png'
import eelsIcon from '../assets/eels/eels-icon.png'
import eelsPlayer from '../assets/eels/eels-player.png'
import eelsBar from '../assets/eels/eels-bar.png'
import eelsCombined from '../assets/eels/eels-combo.png'

//Knights

import knightsType from '../assets/knights/newcastle-type.png'
import knightsIcon from '../assets/knights/newcastle-icon.png'
import knightsPlayer from '../assets/knights/newcastle-player.png'
import knightsBar from '../assets/knights/newcastle-bar.png'
import knightsCombined from '../assets/knights/newcastle-combo.png'

//Manly

import manlyType from '../assets/manly/manly-type.png'
import manlyIcon from '../assets/manly/manly-icon.png'
import manlyPlayer from '../assets/manly/manly-player.png'
import manlyBar from '../assets/manly/manly-bar.png'
import manlyCombined from '../assets/manly/manly-combo.png'

//Panthers

import panthersType from '../assets/panthers/panthers-type.png'
import panthersIcon from '../assets/panthers/panthers-icon.png'
import panthersPlayer from '../assets/panthers/panthers-player.png'
import panthersBar from '../assets/panthers/panthers-bar.png'
import panthersCombined from '../assets/panthers/panthers-combo.png'

//Rabbitohs

import rabbitohsType from '../assets/rabbitohs/rabbits-type.png'
import rabbitohsIcon from '../assets/rabbitohs/rabbits-icon.png'
import rabbitohsPlayer from '../assets/rabbitohs/rabbits-player.png'
import rabbitohsBar from '../assets/rabbitohs/rabbits-bar.png'
import rabbitohsCombined from '../assets/rabbitohs/rabbits-combo.png'

//Raiders

import raidersType from '../assets/raiders/raiders-type.png'
import raidersIcon from '../assets/raiders/raiders-icon.png'
import raidersPlayer from '../assets/raiders/raiders-player.png'
import raidersBar from '../assets/raiders/raiders-bar.png'
import raidersCombined from '../assets/raiders/raiders-combo.png'

//Roosters

import roostersType from '../assets/roosters/roosters-type.png'
import roostersIcon from '../assets/roosters/roosters-icon.png'
import roostersPlayer from '../assets/roosters/roosters-player.png'
import roostersBar from '../assets/roosters/roosters-bar.png'
import roostersCombined from '../assets/roosters/roosters-combo.png'

//Sharks

import sharksType from '../assets/sharks/sharks-type.png'
import sharksIcon from '../assets/sharks/sharks-icon.png'
import sharksPlayer from '../assets/sharks/sharks-player.png'
import sharksBar from '../assets/sharks/sharks-bar.png'
import sharksCombined from '../assets/sharks/sharks-combo.png'

//Storm

import stormType from '../assets/storm/melbourne-type.png'
import stormIcon from '../assets/storm/melbourne-icon.png'
import stormPlayer from '../assets/storm/melbourne-player.png'
import stormBar from '../assets/storm/melbourne-bar.png'
import stormCombined from '../assets/storm/melbourne-combo.png'

//Tigers

import tigersType from '../assets/tigers/tigers-type.png'
import tigersIcon from '../assets/tigers/tigers-icon.png'
import tigersPlayer from '../assets/tigers/tigers-player.png'
import tigersBar from '../assets/tigers/tigers-bar.png'
import tigersCombined from '../assets/tigers/tigers-combo.png'

//Titans

import titansType from '../assets/titans/titans-type.png'
import titansIcon from '../assets/titans/titans-icon.png'
import titansPlayer from '../assets/titans/titans-player.png'
import titansBar from '../assets/titans/titans-bar.png'
import titansCombined from '../assets/titans/titans-combo.png'


//Warriors

import warriorsType from '../assets/warriors/warriors-type.png'
import warriorsIcon from '../assets/warriors/warriors-icon.png'
import warriorsPlayer from '../assets/warriors/warriors-player.png'
import warriorsBar from '../assets/warriors/warriors-bar.png'
import warriorsCombined from '../assets/warriors/warriors-combo.png'

import broncosStrip from '../assets/broncos/strip.png';
import raidersStrip from '../assets/raiders/strip.png';
import bulldogsStrip from '../assets/bulldogs/strip.png';
import sharksStrip from '../assets/sharks/strip.png';
import dragonsStrip from '../assets/dragons/strip.png';
import cowboysStrip from '../assets/cowboys/strip.png';
import eelsStrip from '../assets/eels/strip.png';
import knightsStrip from '../assets/knights/strip.png';
import manlyStrip from '../assets/manly/strip.png';
import panthersStrip from '../assets/panthers/strip.png';
import roostersStrip from '../assets/roosters/strip.png';
import rabbitohsStrip from '../assets/rabbitohs/strip.png';
import titansStrip from '../assets/titans/strip.png';
import warriorsStrip from '../assets/warriors/strip.png';
import tigersStrip from '../assets/tigers/strip.png';
import stormStrip from '../assets/storm/strip.png';

export const teamData = [
  {
    "name": "Brisbane Broncos",
    "shortName": "Broncos",
    "color": new THREE.Color(0x790404).convertSRGBToLinear(),
    "hex": "#790404",
    "strip": broncosStrip,
    "type": broncosType,
    "icon": broncosIcon,
    "player": broncosPlayer,
    "bar": broncosBar,
    "combined": broncosCombined,
    "established": 1987,
    "premierships": '1992, 1993, 1997, 1998, 2000, 2006',
    "local": "The Caxton",
    "goat": "Darren Lockyer",
    "futureImmortal": "Payne Haas",
  },
  {
    "name": "Canberra Raiders",
    "shortName": "Raiders",
    "color": new THREE.Color(0x99D056).convertSRGBToLinear(),
    "hex": "#99D056",
    "strip": raidersStrip,
    "type": raidersType,
    "icon": raidersIcon,
    "player": raidersPlayer,
    "bar": raidersBar,
    "combined": raidersCombined,
    "established": 1981,
    "premierships": '1989, 1994, 2004',
    "local": "The Duxton",
    "goat": "Mal Meninga",
    "futureImmortal": "Jarrod Croker",
  },
  {
    "name": "Canterbury Bulldogs",
    "shortName": "Bulldogs",
    "color": new THREE.Color(0x1A4D92).convertSRGBToLinear(),
    "hex": "#1A4D92",
    "strip": bulldogsStrip,
    "type": bulldogsType,
    "icon": bulldogsIcon,
    "player": bulldogsPlayer,
    "bar": bulldogsBar,
    "combined": bulldogsCombined,
    "established": 1935,
    "premierships": '1938, 1942, 1980, 1984, 1985, 1988, 1995, 2004',
    "local": "Twin Willows",
    "goat": "Terry Lamb",
    "futureImmortal": "Josh Addo-Carr",
  },
  {
    "name": "Cronulla Sharks",
    "shortName": "Sharks",
    "color": new THREE.Color(0x638FCB).convertSRGBToLinear(),
    "hex": "#638FCB",
    "strip": sharksStrip,
    "type": sharksType,
    "icon": sharksIcon, 
    "player": sharksPlayer,
    "bar": sharksBar,
    "combined": sharksCombined,
    "established": 1963,
    "premierships": '2016',
    "local": "Northies",
    "goat": "Steve Rogers",
    "futureImmortal": "Nicho Hynes",
  },
  {
    "name": "Gold Coast Titans",
    "shortName": "Titans",
    "color": new THREE.Color(0x00A3E0).convertSRGBToLinear(),
    "hex": "#00A3E0",
    "strip": titansStrip,
    "type": titansType,
    "icon": titansIcon,
    "player": titansPlayer,
    "bar": titansBar,
    "combined": titansCombined,
    "established": 2007,
    "premierships": 'N/A',
    "local": "The Cavill",
    "goat": "Scott Prince",
    "futureImmortal": "David Fifita",
  },
  {
    "name": "Manly Sea Eagles",
    "shortName": "Sea Eagles",
    "color": new THREE.Color(0x871D53).convertSRGBToLinear(),
    "hex": "#871D53",
    "strip": manlyStrip,
    "type": manlyType,
    "icon": manlyIcon,
    "player": manlyPlayer,
    "bar": manlyBar,
    "combined": manlyCombined,
    "established": 1946,
    "premierships": '1972, 1973, 1976, 1978, 1987, 1996, 2008, 2011',
    "local": "Steyne",
    "goat": "Bob Fulton",
    "futureImmortal": "Tom Trbojevic",
  },
  {
    "name": "Melbourne Storm",
    "shortName": "Storm",
    "color": new THREE.Color(0x5D349D).convertSRGBToLinear(),
    "hex": "#5D349D",
    "strip": stormStrip,
    "type": stormType,
    "icon": stormIcon,
    "player": stormPlayer,
    "bar": stormBar,
    "combined": stormCombined,
    "established": 1997,
    "premierships": '1999, 2012, 2017, 2020',
    "local": "The Limerick Arms",
    "goat": "Cameron Smith",
    "futureImmortal": "Harry Grant",
  },
  {
    "name": "New Zealand Warriors",
    "shortName": "Warriors",
    "color": new THREE.Color(0x008445).convertSRGBToLinear(),
    "hex": "#008445",
    "strip": warriorsStrip,
    "type": warriorsType,
    "icon": warriorsIcon,
    "player": warriorsPlayer,
    "bar": warriorsBar,
    "combined": warriorsCombined,
    "established": 1992,
    "premierships": 'N/A',
    "local": "The Kingslander",
    "goat": "Stacey Jones",
    "futureImmortal": "Shaun Johnson",
  },
  {
    "name": "Newcastle Knights",
    "shortName": "Knights",
    "color": new THREE.Color(0x14325F).convertSRGBToLinear(),
    "hex": "#14325F",
    "strip": knightsStrip,
    "type": knightsType,
    "icon": knightsIcon,
    "player": knightsPlayer,
    "bar": knightsBar,
    "combined": knightsCombined,
    "established": 1987,
    "premierships": '1997, 2001',
    "local": "Honeysuckle",
    "goat": "Andrew Johns",
    "futureImmortal": "Kalyn Ponga",
  },
  {
    "name": "North QLD Cowboys",
    "shortName": "Cowboys",
    "color": new THREE.Color(0xFFD100).convertSRGBToLinear(),
    "hex": "#FFD100",
    "strip": cowboysStrip,
    "type": cowboysType,
    "icon": cowboysIcon,
    "player": cowboysPlayer,
    "bar": cowboysBar,
    "combined": cowboysCombined,
    "established": 1995,
    "premierships": '2015',
    "local": "The Hinchinbrook",
    "goat": "Jonathan Thurston",
    "futureImmortal": "Jason Taumalolo",
  },
  {
    "name": "Parramatta Eels",
    "shortName": "Eels",
    "color": new THREE.Color(0x0B3784).convertSRGBToLinear(),
    "hex": "#0B3784",
    "strip": eelsStrip,
    "type": eelsType,
    "icon": eelsIcon,
    "player": eelsPlayer,
    "bar": eelsBar,
    "combined": eelsCombined,
    "established": 1946,
    "premierships": '1981, 1982, 1983, 1986',
    "local": "Commercial Hotel",
    "goat": "Peter Sterling",
    "futureImmortal": "Junior Paulo",
  },
  {
    "name": "Penrith Panthers",
    "shortName": "Panthers",
    "color": new THREE.Color(0xFC76AB).convertSRGBToLinear(),
    "hex": "#FC76AB",
    "strip": panthersStrip,
    "type": panthersType,
    "icon": panthersIcon,
    "player": panthersPlayer,
    "bar": panthersBar,
    "combined": panthersCombined,
    "established": 1966,
    "premierships": '1991, 2003, 2021, 2022',
    "local": "Daily Planet",
    "goat": "Greg Alexander",
    "futureImmortal": "Nathan Cleary",
  },
  {
    "name": "South Sydney Rabbitohs",
    "shortName": "Rabbitohs",
    "color": new THREE.Color(0x1F4E27).convertSRGBToLinear(),
    "hex": "#1F4E27",
    "strip": rabbitohsStrip,
    "type": rabbitohsType,
    "icon": rabbitohsIcon,
    "player": rabbitohsPlayer,
    "bar": rabbitohsBar,
    "combined": rabbitohsCombined,
    "established": 1908,
    "premierships": '1908, 1909, 1914, 1918, 1925, 1926, 1927, 1928, 1929, 1931, 1932, 1950, 1951, 1953, 1954, 1955, 1967, 1968, 1970, 1971, 2014',
    "local": "Tudor Hotel",
    "goat": "Clive Churchill",
    "futureImmortal": "Latrell Mitchell",
  },
  {
    "name": "St George Dragons",
    "shortName": "Dragons",
    "color": new THREE.Color(0xE82C2A).convertSRGBToLinear(),
    "hex": "#E82C2A",
    "strip": dragonsStrip,
    "type": dragonsType,
    "icon": dragonsIcon,
    "player": dragonsPlayer,
    "bar": dragonsBar,
    "combined": dragonsCombined,
    "established": '1998 (Merger)',
    "premierships": '1941, 1949, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1977, 1979, 2010',
    "local": "The Illawarra",
    "goat": "Johnny Raper",
    "futureImmortal": "Ben Hunt",
  },
  {
    "name": "Sydney Roosters",
    "shortName": "Roosters",
    "color": new THREE.Color(0x2100A3).convertSRGBToLinear(),
    "hex": "#2100A3",
    "strip": roostersStrip,
    "type": roostersType,
    "icon": roostersIcon,
    "player": roostersPlayer,
    "bar": roostersBar,
    "combined": roostersCombined,
    "established": 1908,
    "premierships": '1911, 1912, 1913, 1923, 1935, 1936, 1937, 1940, 1945, 1974, 1975, 2002, 2013, 2018, 2019',
    "local": "The Golden Sheaf",
    "goat": "Arthur Beetson",
    "futureImmortal": "James Tedesco",
  },
  {
    "name": "Wests Tigers",
    "shortName": "Tigers",
    "color": new THREE.Color(0xF77111).convertSRGBToLinear(),
    "hex": "#F77111",
    "strip": tigersStrip,
    "type": tigersType,
    "icon": tigersIcon,
    "player": tigersPlayer,
    "bar": tigersBar,
    "combined": tigersCombined,
    "established": '1999 (Merger)',
    "premierships": '1915, 1916, 1917, 1919, 1920, 1924, 1930, 1934, 1939, 1944, 1946, 1947, 1948, 1952, 1969, 2005',
    "local": "The Orange Grove",
    "goat": "Benji Marshall",
    "futureImmortal": "Luke Brooks",
  },
]