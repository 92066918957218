import React, {useState} from 'react'
import styled from 'styled-components'
import {teamData} from "../data/teamData";
import {useStore} from "../stores/useStore";


const Holder = styled.div`
  cursor: pointer;
  position: fixed;
  height: var(--windowHeight);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 180;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  background: var(--backgroundGreen);
  overflow: hidden;
  
  .start {
    margin-top: 2rem;
  }

  &:before {
    content: "";
    position: absolute;
    width: 117.92%;
    height: 25.92%;
    left: -7.85%;
    top: 93.82%;
    background-color: #004720;
    filter: blur(100px);
    border-radius: 50%;
  }

`

const TeamList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.2rem 1rem;
  max-width: 50rem;
  margin: 2rem 0;
  padding: 0 2rem;
  button {
    border: none;
    font-size: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    white-space: normal;
    .dot {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      border: 1px solid;
    }
    .longName {
      display: none;
      @media( ${props => props.theme.breakpoints.sm} ) {
        display: inline-block;
      }
    }
    .shortName {
      @media( ${props => props.theme.breakpoints.sm} ) {
        display: none;
      }
    }
  }
`;


const OpeningTeamFilter = () => {

  const [showOverlay, setShowOverlay] = useState(true);
  const teams = useStore((state) => state.teams);
  const addTeam = useStore((state) => state.addTeam);
  const removeTeam = useStore((state) => state.removeTeam);

  if(!showOverlay) return null;

  return (
    <Holder className={`overlay ${showOverlay ? 'show' : 'hide'}`}>
      <p>Pick your team and <br/>the team you most <em>despise</em>...</p>
        <TeamList>
          {teamData.map((team, index) => (
            <button className="team-name"
                    key={index}
                    onClick={() => {
                      // When the button is clicked, add or remove the team from the teams array
                      if (teams.includes(team.name)) {
                        removeTeam(team.name);
                      } else {
                        addTeam(team.name);
                      }
                    }}
            >
              <span className="dot" style={{
                borderColor: team.hex,
                background: teams.includes(team.name) ? team.hex : 'transparent',
              }}></span>
              <span className="shortName">{team.shortName.toUpperCase()}</span>
              <span className="longName">{team.name.toUpperCase()}</span>
            </button>
          ))}
        </TeamList>
        <p>...and any others you want to <br/>compare your team's performance with</p>
        <button className="green start" onClick={() => {
          setShowOverlay(false)
        }}>Start
        </button>
    </Holder>
  )
}

export default OpeningTeamFilter